<template>
  <PopoverPortal>
    <PopoverContent ref="wrapper" v-bind="{ ...forwarded, ...$attrs }">
      <slot></slot>
    </PopoverContent>
  </PopoverPortal>
</template>

<script setup lang="ts">
import {
  PopoverContent,
  type PopoverContentEmits,
  type PopoverContentProps,
  PopoverPortal,
  useForwardPropsEmits,
} from "radix-vue";

const props = defineProps<PopoverContentProps>();
const emits = defineEmits<PopoverContentEmits>();

const forwarded = useForwardPropsEmits(props, emits);

// We have to handle trapFocus issue in the PopoverContent component
const wrapper = ref();
const focused = ref();
const { handleFocusTrap } = useInjectFocusTrap();
onMounted(() => {
  focused.value = useFocus(wrapper);
});
watch(focused, () => {
  handleFocusTrap(focused.value);
});
</script>
