<template>
  <PopoverTrigger v-bind="props">
    <slot></slot>
  </PopoverTrigger>
</template>

<script setup lang="ts">
import { PopoverTrigger, type PopoverTriggerProps } from "radix-vue";

const props = defineProps<PopoverTriggerProps>();
</script>
